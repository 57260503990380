import React from "react";
import { graphql } from "gatsby";
import PageWrapper from "components/PageWrapper";
import GatsbyLink from "gatsby-link";
import GatsbyImage from "gatsby-image";
import Image from "gatsby-image";
import log from "utils/log";
import DogCard from "components/DogCard";
import HollowCircularButton from "components/HollowCircularButton";
import SolidCircularButton from "components/SolidCircularButton";
import * as coreTheme from "@bit/cpinnix.verious.theme";
import * as siteTheme from "theme";
import * as heroClasses from "./hero.module.css";

export const query = graphql`
  query {
    descriptionImage: contentfulAsset(
      contentful_id: { eq: "4MO4JDc5UBW9bUV9oR5DaR" }
    ) {
      id
      fixed {
        src
      }
    }
    heroImage: contentfulAsset(
      contentful_id: { eq: "6nv1lKctpOyuHALXC7XFr5" }
    ) {
      id
      fluid {
        ...GatsbyContentfulFluid_withWebp
      }
    }
    dogs: allContentfulAdoptDog {
      edges {
        node {
          id
          name
          sex
          weight
          breed
          adopted
          age
          adoptionLink
          picture {
            fluid(maxHeight: 640, maxWidth: 480, resizingBehavior: THUMB) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
    }
  }
`;

function presentData(data) {
  const seo = {
    title: "Adoptable Dogs",
    image: data?.descriptionImage?.fixed?.src,
    description:
      "Nana's Haven in St. Petersburg, FL offers support and services for neglected and abused animals. Learn more about us today.",
  };

  const presentation = {
    seo,
    adoptableDogs: data?.dogs?.edges
      .map((edge) => edge.node)
      .filter(({ adopted }) => !adopted),
    heroImage: data?.heroImage?.fluid,
  };

  log("[PAGE] presentation", presentation);

  return presentation;
}

export default function Page({ data }) {
  const { adoptableDogs, seo, heroImage } = presentData(data);

  return (
    <PageWrapper seo={seo}>
      {/* Hero ---> */}
      <div className="container mx-auto px-8 md:px-16">
        <div className={heroClasses.heroLayout}>
          <div className={heroClasses.heroText}>
            <div className={heroClasses.heroTextLayout}>
              <h1 className={heroClasses.heroTitle}>Our Dogs</h1>
              <div className={heroClasses.heroContent}>
                <p className={heroClasses.heroBlurb}>Be a forever home.</p>
                <a
                  href="https://docs.google.com/forms/d/e/1FAIpQLSc6Min48Fw0_iSXVij_AyXfN15I9FMBx4eLU1vY0dGJmVe22g/viewform"
                  rel="noreferrer"
                  target="_blank"
                >
                  <SolidCircularButton
                    theme={{
                      backgroundColor: siteTheme.button.primary,
                      color: coreTheme.white,
                    }}
                  >
                    Fill out an application
                  </SolidCircularButton>
                </a>
              </div>
            </div>
          </div>
          <div className={heroClasses.heroImage}>
            <GatsbyImage fluid={heroImage} />
          </div>
        </div>
      </div>
      {/* <---- Hero */}

      <div className="bg-pink-100 py-32">
        <div className="container mx-auto sm:px-8 md:px-16">
          <div className="sm:grid sm:gap-8 sm:grid-cols-2 lg:grid-cols-3">
            {adoptableDogs.map((dog, index) => (
              <div
                key={index}
                className="md:col-span-1 lg:col-span-1 xl:col-span-1"
              >
                <DogCard
                  key={dog.id}
                  Image={
                    <GatsbyLink to={`/dog/${dog.id}`}>
                      <Image fluid={dog.picture[0].fluid} />
                    </GatsbyLink>
                  }
                  LearnMoreCallToAction={
                    <GatsbyLink to={`/dog/${dog.id}`}>
                      <HollowCircularButton
                        wide
                        theme={{
                          borderColor: coreTheme.gray[500],
                          color: coreTheme.gray[600],
                        }}
                      >
                        Learn More
                      </HollowCircularButton>
                    </GatsbyLink>
                  }
                  AdoptionCallToAction={
                    dog.adoptionLink && (
                      <a
                        href={dog.adoptionLink}
                        rel="noreferrer"
                        target="_blank"
                      >
                        <SolidCircularButton
                          wide
                          theme={{
                            backgroundColor: siteTheme.button.primary,
                            color: coreTheme.white,
                          }}
                        >
                          Adopt Me
                        </SolidCircularButton>
                      </a>
                    )
                  }
                  name={dog.name}
                  sex={dog.sex}
                  age={dog.age}
                  theme={{
                    wrapper: {
                      backgroundColor: coreTheme.white,
                    },
                    adopted: {
                      color: coreTheme.white,
                      backgroundColor: "#f27d77",
                    },
                  }}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </PageWrapper>
  );
}
